<trp-community-widget>
  <trp-community-widget-header
    [title]="widgetDefinition.title | tl8"
    [icon]="widgetDefinition.icon"
    [infoText]="widgetDefinition.infoText ?? '' | tl8"
  ></trp-community-widget-header>
  <trp-community-widget-content>
    <div class="widget-content">
      <ng-container
        *ngIf="widgetDefinition.ifInfoAvailable(sectionCommunityInfo); else na"
      >
        <ng-container
          *ngIf="
            widgetDefinition?.availableValueFormat === 'html';
            else regularFormat
          "
        >
          <div
            [innerHTML]="
              widgetDefinition.availableResolver(sectionCommunityInfo)
            "
          ></div>
          <div
            *ngIf="
              !!widgetDefinition.measurementYear &&
              widgetDefinition.measurementYear(sectionCommunityInfo)
            "
            class="reported-year"
          >
            {{ widgetDefinition.measurementYear(sectionCommunityInfo) }}
          </div>
        </ng-container>
        <ng-template #regularFormat>
          {{ widgetDefinition.availableResolver(sectionCommunityInfo) }}
        </ng-template>
      </ng-container>
      <ng-template #na>
        {{ widgetDefinition.unavailableResolver(sectionCommunityInfo) }}
      </ng-template>
    </div>
  </trp-community-widget-content>
</trp-community-widget>
