import { Pipe, PipeTransform } from '@angular/core';
import { MultiFamilyProgram } from '@trp/nationaldb/interfaces';

@Pipe({ name: 'additionalServices', standalone: true })
export class AdditionalServicesPipe implements PipeTransform {
  transform(program: MultiFamilyProgram, ifNotAvailableString = 'N/A'): string {
    const services = this.getAdditionalServices(program);
    if (!program || services.length === 0) {
      return ifNotAvailableString;
    }
    return services.join(', ');
  }

  private getAdditionalServices(program: MultiFamilyProgram): string[] {
    const services: string[] = [];
    if (program.hasEducation) {
      services.push('Education');
    }
    if (program.hasTechnicalSupport) {
      services.push('Technical Support');
    }
    if (program.hasOrdinance) {
      services.push('Ordinance');
    }
    if (program.hasEnforcement) {
      services.push('Enforcement');
    }
    return services;
  }
}
