import { InjectionToken } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CartSizesPipe,
  ContainersPipe,
  CurbsideContaminationPipe,
  AdditionalServicesPipe,
  MethodsPipe,
} from '@trp/community/shared/ui';
import { CommunityWidgetInfoUtilService } from '../services';
import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { CommunityProfileWidgetConfig } from '@trp/community/interfaces';
import generalInformation from './generalInformation';
import singleFamilyPrograms from './singleFamily';
import multiFamilyPrograms from './multiFamily';
import dropoffPrograms from './dropoff';
import programOutputs from './programOutputs';
import censusData from './censusData';

export const profilesWidgetConfigFactory = (
  translateService: TranslateService,
  utilService: CommunityWidgetInfoUtilService,
  containersPipe: ContainersPipe,
  decimalPipe: DecimalPipe,
  currencyPipe: CurrencyPipe,
  percentPipe: PercentPipe,
  curbsideContaminationPipe: CurbsideContaminationPipe,
  cartSizesPipe: CartSizesPipe,
  additionalServicePipe: AdditionalServicesPipe,
  methodsPipe: MethodsPipe
): CommunityProfileWidgetConfig => {
  return {
    generalInformation: generalInformation(translateService, decimalPipe),
    singleFamilyPrograms: singleFamilyPrograms(
      translateService,
      decimalPipe,
      containersPipe,
      curbsideContaminationPipe,
      cartSizesPipe,
      methodsPipe
    ),
    multiFamilyPrograms: multiFamilyPrograms(
      translateService,
      decimalPipe,
      containersPipe,
      utilService,
      additionalServicePipe,
      methodsPipe
    ),
    dropoffPrograms: dropoffPrograms(
      translateService,
      decimalPipe,
      methodsPipe
    ),
    programOutputs: programOutputs(translateService, decimalPipe),

    censusData: censusData(
      translateService,
      decimalPipe,
      currencyPipe,
      percentPipe
    ),
  };
};

export const PROFILES_WIDGET_CONFIG_TOKEN =
  new InjectionToken<CommunityProfileWidgetConfig>(
    'PROFILES_WIDGET_CONFIG_TOKEN'
  );
