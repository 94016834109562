import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { CommunityProfileWidgetConfig } from '@trp/community/interfaces';
import { PrivateCommunityInformation } from '@trp/nationaldb/interfaces';

const programOutputs = (
  translateService: TranslateService,
  decimalPipe: DecimalPipe
): CommunityProfileWidgetConfig['programOutputs'] => ({
  section: 'programOutputs',
  title: 'widgets.programOutput.title',
  maxColumns: 2,
  propertiesToVerify: [],
  widgets: [
    {
      section: 'programOutputs',
      infoType: 'recyclingTonsTotal',
      infoText: 'requestForm.questions.programOutputs.totalTonnageInfo',
      type: 'standard',
      locations: ['community-profile', 'map-profile'],

      title: 'widgets.programOutput.recyclingTonsTotal',
      icon: 'custom-solid:TonsRecycled',
      ifInfoAvailable: (info: PrivateCommunityInformation) =>
        (info.totalTonnage || 0) > 0,
      availableResolver: (info: PrivateCommunityInformation) =>
        decimalPipe.transform(info.totalTonnage, '1.0-0'),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.programOutput.notice.noTotalTonnageInfo'
        ),
    },
    {
      section: 'programOutputs',
      infoType: 'metricTonsCO2AvoidedPerYear',
      infoText: 'widgets.programOutput.co2SavingsInfo',
      type: 'standard',
      locations: ['community-profile', 'map-profile'],

      title: 'widgets.programOutput.co2Savings',
      icon: 'custom-solid:CO2',
      ifInfoAvailable: (info: PrivateCommunityInformation) =>
        (info.totalTonnage || 0) > 0 &&
        (info.metricTonsCO2AvoidedPerYear || 0) > 0,
      availableResolver: (info: PrivateCommunityInformation) =>
        decimalPipe.transform(info.metricTonsCO2AvoidedPerYear, '1.0-0'),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.programOutput.notice.noTonsRecyclablesInfo'
        ),
    },
  ],
});

export default programOutputs;
