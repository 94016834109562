import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cartSizes', standalone: true })
export class MethodsPipe implements PipeTransform {
  transform(value: string[] | null | undefined, ifNotAvailableString = 'N/A') {
    if (!value || value.length === 0) {
      return '';
    }

    let html = `<ul class="bullet-list">`;
    value.forEach((item) => {
      html += `<li>${item}</li>`;
    });
    html += `</ul>`;

    return html;
  }
}
