import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { CommunityProfileWidgetConfig } from '@trp/community/interfaces';
import { PrivateCommunityInformation } from '@trp/nationaldb/interfaces';

const censusData = (
  translateService: TranslateService,
  decimalPipe: DecimalPipe,
  currencyPipe: CurrencyPipe,
  percentPipe: PercentPipe
): CommunityProfileWidgetConfig['censusData'] => ({
  section: 'censusData',
  title: 'widgets.censusData.title',
  maxColumns: 3,
  propertiesToVerify: [],
  widgets: [
    {
      section: 'censusData',
      infoType: 'totalPopulation',
      type: 'standard',
      locations: ['community-profile', 'map-profile'],

      title: 'widgets.censusData.population',
      icon: 'custom-solid:Population',
      ifInfoAvailable: (info: PrivateCommunityInformation) =>
        (info.totalPopulation || 0) > 0,
      availableResolver: (info: PrivateCommunityInformation) =>
        decimalPipe.transform(info.totalPopulation, '1.0-0'),
      unavailableResolver: () =>
        translateService.instant('widgets.censusData.notice.noPopulationInfo'),
    },
    {
      section: 'censusData',
      infoType: 'occupancyRate',
      type: 'standard',
      locations: ['community-profile', 'map-profile'],

      title: 'widgets.censusData.occupancy',
      icon: 'global:OccupancyRate',
      ifInfoAvailable: (info: PrivateCommunityInformation) =>
        (info.occupancyRate ?? 0) > 0,
      availableResolver: (info: PrivateCommunityInformation) =>
        percentPipe.transform((info.occupancyRate ?? 0) / 100),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.censusData.notice.noOccupancyRateInfo'
        ),
    },
    {
      section: 'censusData',
      infoType: 'percentRaceBipoc',
      type: 'standard',
      locations: ['community-profile', 'map-profile'],

      title: 'widgets.censusData.bipoc',
      icon: 'custom-solid:BIPOC',
      ifInfoAvailable: (info: PrivateCommunityInformation) => {
        return (
          typeof info.percentRaceBipoc === 'number' &&
          info.percentRaceBipoc > -1
        );
      },
      availableResolver: (info: PrivateCommunityInformation) =>
        percentPipe.transform((info.percentRaceBipoc ?? 0) / 100),
      unavailableResolver: () =>
        translateService.instant('widgets.censusData.notice.noBipocInfo'),
    },
    {
      section: 'censusData',
      infoType: 'percentBachelorsOrHigher',
      type: 'standard',
      locations: ['community-profile', 'map-profile'],

      title: 'widgets.censusData.bscOrHigher',
      icon: 'custom-solid:BachelorsDegree',
      ifInfoAvailable: (info: PrivateCommunityInformation) => {
        return (
          typeof info.percentBachelorsOrHigher === 'number' &&
          info.percentBachelorsOrHigher > -1
        );
      },
      availableResolver: (info: PrivateCommunityInformation) =>
        percentPipe.transform((info.percentBachelorsOrHigher ?? 0) / 100),
      unavailableResolver: () =>
        translateService.instant('widgets.censusData.notice.noEducationInfo'),
    },
    {
      section: 'censusData',
      infoType: 'incomeMean',
      type: 'standard',
      locations: ['community-profile', 'map-profile'],

      title: 'widgets.censusData.medianIncome',
      icon: 'custom-solid:MedianIncom',
      ifInfoAvailable: (info: PrivateCommunityInformation) =>
        (info.incomeMean || 0) > 0,
      availableResolver: (info: PrivateCommunityInformation) =>
        currencyPipe.transform(info.incomeMean, '$', 'symbol', '1.0-0'),
      unavailableResolver: () =>
        translateService.instant('widgets.censusData.notice.noIncomeInfo'),
    },
  ],
});

export default censusData;
