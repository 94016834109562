import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import {
  CommunityProfileWidgetConfig,
  DROPOFF_VERIFIABLE_PROPERTIES,
} from '@trp/community/interfaces';
import { MethodsPipe } from '@trp/community/shared/ui';
import { DropoffProgram, MultiFamilyProgram } from '@trp/nationaldb/interfaces';
import { poundsPerHousehold } from './community-widget-config.util';

const dropoffPrograms = (
  translateService: TranslateService,
  decimalPipe: DecimalPipe,
  methodsPipe: MethodsPipe
): CommunityProfileWidgetConfig['dropoffPrograms'] => ({
  section: 'dropoffPrograms',
  title: 'widgets.dropoffProgram.title',
  maxColumns: 3,
  propertiesToVerify: [...DROPOFF_VERIFIABLE_PROPERTIES],
  widgets: [
    {
      section: 'dropoffPrograms',
      infoType: 'householdsServed',
      isReadonly: true,
      type: 'standard',
      locations: ['community-profile', 'map-profile'],

      title: 'widgets.dropoffProgram.householdsServed',
      icon: 'global:HouseholdsServed',
      infoText: 'requestForm.questions.dropoff.householdsServedInfo',
      ifInfoAvailable: (info: DropoffProgram) =>
        (info.householdsServed || 0) > 0,
      availableResolver: (info: DropoffProgram) =>
        decimalPipe.transform(info.householdsServed, '1.0-0'),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.dropoffProgram.notice.noHouseholdInfo'
        ),
    },
    {
      section: 'dropoffPrograms',
      infoType: 'tonnage',
      type: 'standard',
      locations: ['community-profile', 'map-profile'],
      title: 'widgets.dropoffProgram.recyclingTons',
      icon: 'custom-solid:TonsRecycled',
      infoText: 'requestForm.questions.dropoff.dropoffTonnageInfo',
      ifInfoAvailable: (info: DropoffProgram) => !!info.tonnage,
      availableResolver: (info: DropoffProgram) =>
        decimalPipe.transform(info.tonnage, '1.0-0'),
      unavailableResolver: () =>
        translateService.instant('widgets.dropoffProgram.notice.noTonnageInfo'),
    },
    {
      section: 'dropoffPrograms',
      infoType: 'poundsPerHousehold',
      infoText: 'requestForm.questions.singleFamily.poundsPerHouseholdInfo',
      isReadonly: true,
      type: 'standard',
      locations: ['community-profile', 'map-profile'],

      title: 'widgets.dropoffProgram.poundsPerHousehold',
      icon: 'custom-solid:TonsRecycled',

      ifInfoAvailable: (info: DropoffProgram) =>
        (info.poundsPerHousehold || 0) > 0 ||
        (!!info.tonnage && !!info.householdsServed),
      availableResolver: (info: DropoffProgram) =>
        decimalPipe.transform(poundsPerHousehold(info), '1.0-2'),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.dropoffProgram.notice.noPoundsPerHouseholdInfo'
        ),
    },
    {
      section: 'dropoffPrograms',
      infoType: 'contaminationRatePercentage',
      type: 'standard',
      locations: ['community-profile', 'map-profile'],

      title: 'widgets.dropoffProgram.contaminationRate',
      icon: 'custom-solid:ContaminationRate',
      infoText: 'requestForm.questions.dropoff.contaminationRatePercentageInfo',
      ifInfoAvailable: (info: DropoffProgram) =>
        (info.contaminationRatePercentage || 0) > 0,
      availableResolver: (info: DropoffProgram) =>
        `${decimalPipe.transform(info.contaminationRatePercentage, '1.0-2')}%`,
      unavailableResolver: () =>
        translateService.instant(
          'widgets.dropoffProgram.notice.noDropoffContaminationRateInfo'
        ),
    },
    {
      section: 'dropoffPrograms',
      infoType: 'contaminationRateHow',
      type: 'standard',
      locations: ['community-profile', 'map-profile'],
      availableValueFormat: 'html',
      title: 'widgets.dropoffProgram.contaminationRateMethods',
      icon: 'custom-solid:ContaminationRate',
      ifInfoAvailable: (info: DropoffProgram) =>
        (info.contaminationRateHow || []).length > 0,
      availableResolver: (info: DropoffProgram) =>
        methodsPipe.transform(info.contaminationRateHow),
      unavailableResolver: () =>
        translateService.instant(
          'widgets.dropoffProgram.notice.noDropoffContaminationMethodsInfo'
        ),
    },
  ],
});

export default dropoffPrograms;
