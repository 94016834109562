import { Pipe, PipeTransform } from '@angular/core';
import { SingleFamilyProgram } from '@trp/nationaldb/interfaces';

@Pipe({ name: 'cartSizes', standalone: true })
export class CartSizesPipe implements PipeTransform {
  transform(value: SingleFamilyProgram['cartSizes'] | null | undefined) {
    const writtenOut = value?.map((size) => `${size.split(' ')[0]} gallons`);
    if (!writtenOut || writtenOut.length === 0) {
      return '';
    }

    let html = `<ul class="bullet-list">`;
    writtenOut.forEach((item) => {
      html += `<li>${item}</li>`;
    });
    html += `</ul>`;

    return html;
  }
}
