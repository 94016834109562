<header class="header">
  <a class="logo" [routerLink]="['/']"></a>
  <button *ngIf="expanded" @fadeInOnEnter (click)="menu.collapse()" mat-button>
    Collapse Menu
    <mat-icon>chevron_left</mat-icon>
  </button>
</header>

<button mat-fab class="fab-like" *ngIf="!expanded" (click)="menu.expand()">
  <mat-icon>chevron_right</mat-icon>
</button>

<community-nav role="navigation" aria-label="Main navigation"></community-nav>
<div class="spacer"></div>

<div class="user">
  <trp-user-info *ngIf="userLoaded$ | async; else loading"></trp-user-info>
  <ng-template #loading>
    <div class="loading"></div>
  </ng-template>
</div>
